import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { InView } from 'react-intersection-observer';
import theme from '@greenberry/salal/theme';
import { map, sortBy, prop, reduce, reverse } from 'ramda';
import HeroImage from '@greenberry/salal/components/HeroImage';
import Heading from '@greenberry/salal/components/Heading';
import Section from '@greenberry/salal/components/Section';
import Text from '@greenberry/salal/components/Text';
import Explanation from '@greenberry/salal/components/Explanation';
import Card from '@greenberry/salal/components/Card';
import ComboChart from '@greenberry/salal/components/Charts/ComboChart';
import DonutChart from '@greenberry/salal/components/Charts/Donut';
import StackedBarChart from '@greenberry/salal/components/Charts/StackedBar';
import Legend from '@greenberry/salal/components/Legend';
import mq from '@greenberry/salal/utils/mediaQuery';

import SlideIntoView from '../../../components/SlideIntoView';
import NationalLayout from '../../../components/NationalLayout';
import ThemeFooter from '../../../components/ThemeFooter';
import parseTooltip from '../../../utils/parseTooltip';

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 25%;
`;

const DonutWrapper = styled(GraphWrapper)`
  flex-basis: 75%;
`;

const GraphHeading = styled(Heading)`
  margin-top: ${theme.spacing('m')};
  margin-bottom: 0;
  ${mq('2')} {
    margin-top: ${theme.spacing('l')};
    margin-bottom: 0;
  }
`;

const formatIndicator = (indicator, sector) => {
  const keys = Object.keys(indicator[sector]);
  const total = reduce((acc, key) => indicator[sector][key] + acc, 0, keys);
  return map(
    key => ({
      label: key,
      name: key,
      unit: 'percentage',
      value: Math.round(((100 * indicator[sector][key]) / total) * 100) / 100,
    }),
    keys
  );
};

const formatLegendSource = (indicatorData = []) => {
  const total = reduce(
    (acc, key) => indicatorData[key] + acc,
    0,
    Object.keys(indicatorData)
  );
  const notFilled = indicatorData['Niet ingevuld'];
  return `Bron: ${total - notFilled} van de ${total} samenwerkingverbanden`;
};

const formatLegendItems = (formattedIndicator = []) =>
  reverse(sortBy(prop('value'), formattedIndicator)).map(({ label }, i) => ({
    label,
    color: theme.color(`chart.${i + 1}`),
  }));

const Organization = ({ pageContext: { copy, sector, data }, ...props }) => {
  const shortSector = sector === 'primair-onderwijs' ? 'PO' : 'VO';

  const organizationForm = formatIndicator(data.organizationForm, shortSector);

  const executiveResponsibilities = formatIndicator(
    data.executiveResponsibilities,
    shortSector
  );

  const supervisoryIndependence = formatIndicator(
    data.supervisoryIndependence,
    shortSector
  );
  const supervisoryDuties = formatIndicator(
    data.supervisoryDuties,
    shortSector
  );

  const sideNavOptions = [
    {
      name: 'Organisatievorm',
      id: 'organisatievorm-organizationForm',
    },
    {
      name: 'Onafhankelijkheid',
      id: 'onafhankelijkheid-supervisoryIndependence',
    },
  ];

  const [selectedIndicator, selectIndicator] = useState(sideNavOptions[0].name);

  return (
    <NationalLayout
      title={copy.heroHeading}
      body={copy.heroBody}
      seoMetaTags={copy.seoMetaTags}
      heroImage={<HeroImage src="/images/DPO-organisatie.png" opacity="1" />}
      sideNavOptions={sideNavOptions}
      selectedIndicator={selectedIndicator}
      {...props}
    >
      <Section id={sideNavOptions[0].id}>
        <InView
          threshold={1}
          onChange={inView => {
            if (inView) {
              selectIndicator(sideNavOptions[0].name);
            }
          }}
        >
          <Heading component="h1" size="xxl" appearance="secondary">
            {parseTooltip(copy.organizationFormHeading)}
          </Heading>
        </InView>
        <Text size="m" appearance="base">
          {parseTooltip(copy.organizationFormBody)}
        </Text>
        <SlideIntoView minHeight="250px">
          <Card>
            <ComboChart align="flex-end">
              <GraphWrapper>
                <StackedBarChart data={organizationForm} height={550} />
                <GraphHeading
                  component="h3"
                  size="m"
                  weight="bold"
                  style={{ textAlign: 'center' }}
                >
                  Organisatievorm
                </GraphHeading>
              </GraphWrapper>
              <DonutWrapper>
                <DonutChart
                  data={executiveResponsibilities}
                  height={600}
                  showSubLabels={false}
                />
                <GraphHeading component="h3" size="m" weight="bold">
                  Uitvoerende verantwoordelijkheden liggen bij
                </GraphHeading>
              </DonutWrapper>
            </ComboChart>
            <Legend
              source={formatLegendSource(
                data.executiveResponsibilities[shortSector]
              )}
              data={formatLegendItems(executiveResponsibilities)}
            />
          </Card>
        </SlideIntoView>
        {copy.organizationFormExplanation && (
          <Explanation
            text={copy.organizationFormExplanation}
            title="Toelichting"
          />
        )}
      </Section>

      <Section id={sideNavOptions[1].id}>
        <InView
          threshold={1}
          onChange={inView => {
            if (inView) {
              selectIndicator(sideNavOptions[1].name);
            }
          }}
        >
          <Heading component="h1" size="xxl" appearance="secondary">
            {parseTooltip(copy.supervisoryDutiesHeading)}
          </Heading>
        </InView>
        <Text size="m" appearance="base">
          {parseTooltip(copy.supervisoryDutiesBody)}
        </Text>
        <SlideIntoView minHeight="250px">
          <Card>
            <ComboChart align="flex-end">
              <GraphWrapper>
                <StackedBarChart data={supervisoryIndependence} height={550} />
                <GraphHeading
                  component="h3"
                  size="m"
                  weight="bold"
                  style={{ textAlign: 'center' }}
                >
                  Onafhankelijkheid
                </GraphHeading>
              </GraphWrapper>
              <DonutWrapper>
                <DonutChart
                  data={supervisoryDuties}
                  height={600}
                  showSubLabels={false}
                />
                <GraphHeading
                  appearance="secondary"
                  component="h3"
                  size="l"
                  weight="bold"
                  style={{ textAlign: 'center' }}
                >
                  Toezichthoudende taken liggen bij
                </GraphHeading>
              </DonutWrapper>
            </ComboChart>
            <Legend
              source={formatLegendSource(data.supervisoryDuties[shortSector])}
              data={formatLegendItems(supervisoryDuties)}
            />
          </Card>
        </SlideIntoView>
        {copy.supervisoryDutiesExplanation && (
          <Explanation
            text={copy.supervisoryDutiesExplanation}
            title="Toelichting"
          />
        )}
      </Section>

      <ThemeFooter
        sector={sector}
        prev={{
          label: 'Vorige hoofdstuk: Financiën',
          to: '/financien',
        }}
      />
    </NationalLayout>
  );
};

Organization.propTypes = {
  pageContext: PropTypes.shape({
    sector: PropTypes.string.isRequired,
    copy: PropTypes.shape({
      heroHeading: PropTypes.string.isRequired,
      heroBody: PropTypes.string.isRequired,
      organizationFormHeading: PropTypes.string.isRequired,
      organizationFormBody: PropTypes.string.isRequired,
      organizationFormExplanation: PropTypes.string.isRequired,
      supervisoryDutiesHeading: PropTypes.string.isRequired,
      supervisoryDutiesBody: PropTypes.string.isRequired,
      supervisoryDutiesExplanation: PropTypes.string.isRequired,
      seoMetaTags: PropTypes.shape({}).isRequired,
    }).isRequired,
    data: PropTypes.shape({
      organizationForm: PropTypes.shape({}).isRequired,
      executiveResponsibilities: PropTypes.shape({}).isRequired,
      supervisoryDuties: PropTypes.shape({}).isRequired,
      supervisoryIndependence: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

export default Organization;
