import React, { useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { pluck, find, propEq } from 'ramda';
import theme from '@greenberry/salal/theme';
import Heading from '@greenberry/salal/components/Heading';
import Hero from '@greenberry/salal/components/Hero/v2';
import Text from '@greenberry/salal/components/Text';
import Button from '@greenberry/salal/components/Button';
import Icon from '@greenberry/salal/components/Icon';
import MultiButton from '@greenberry/salal/components/MultiButton';
import MainWrapper from '@greenberry/salal/components/MainWrapper';
import SideNavigation from '@greenberry/salal/components/SideNavigation';
import SEO from '../SEO';
import Layout from '../Layout';
import trackIndicatorInView from '../../utils/trackIndicatorInView';

const NationalLayout = ({
  title,
  body,
  heroImage,
  children,
  location,
  seoMetaTags,
  sideNavOptions = [],
  selectedIndicator,
}) => {
  const heroElement = createRef();
  const [heroHeight, setHeroHeight] = useState(470);

  const options = pluck('name', sideNavOptions);
  const notStart = location.pathname !== '/landelijk-inzicht/';
  const multiButtonOptions = [
    { label: 'Primair onderwijs', value: 'primair-onderwijs' },
    { label: 'Voortgezet onderwijs', value: 'voortgezet-onderwijs' },
  ];

  useEffect(() => {
    setHeroHeight(heroElement.current && heroElement.current.offsetHeight);
  }, [heroElement]);

  useEffect(() => {
    trackIndicatorInView(selectedIndicator);
  }, [selectedIndicator]);

  return (
    <Layout>
      <SEO tags={seoMetaTags.tags} />
      <Hero
        ref={heroElement}
        back={
          notStart && (
            <Button
              color="tertiary"
              disabled={false}
              small
              onClick={() => navigate('/landelijk-inzicht/')}
              styling="ghost"
              type="button"
            >
              <Icon size="lg" type="arrowLeft" />
              <span>terug</span>
            </Button>
          )
        }
        content={
          <>
            <Heading
              appearance="inherit"
              component="h1"
              size="xxxl"
              weight="semibold"
            >
              {title}
            </Heading>
            <Text appearance="inherit" html={false} italic={false} size="l">
              {body}
            </Text>
          </>
        }
        image={heroImage}
      />
      <MainWrapper
        sideNode={
          notStart && (
            <>
              <SideNavigation
                active={selectedIndicator}
                items={options}
                onItemClick={option => {
                  const target = find(propEq('name', option), sideNavOptions);
                  if (target.id) {
                    const heroMargin =
                      global.window &&
                      global.window.matchMedia(
                        `(max-width: ${theme.get('breakpoints.3')})`
                      ).matches
                        ? heroHeight - 20
                        : heroHeight - 30;
                    global.window.scrollTo({
                      top:
                        document.getElementById(target.id).offsetTop +
                        heroMargin,
                      left: 0,
                      behavior: 'smooth',
                    });
                  }
                }}
              />
              <MultiButton
                active={multiButtonOptions.find(({ value }) =>
                  location.pathname.includes(value)
                )}
                color="primary"
                onChange={({ value }) => {
                  const replaceStr = location.pathname.includes(
                    'voortgezet-onderwijs'
                  )
                    ? 'voortgezet-onderwijs'
                    : 'primair-onderwijs';

                  navigate(location.pathname.replace(replaceStr, value));
                }}
                options={multiButtonOptions}
                styling="solid"
              />
            </>
          )
        }
      >
        {children}
      </MainWrapper>
    </Layout>
  );
};

NationalLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  heroImage: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  seoMetaTags: PropTypes.shape({ tags: PropTypes.arrayOf(PropTypes.object) }),
  sideNavOptions: PropTypes.array.isRequired,
  selectedIndicator: PropTypes.string,
};

export default NationalLayout;
